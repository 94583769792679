
// imports
import common from './../common';
import $ from 'jquery';
import debounce from './../global/debounce';

// get URI
const URI = common.URI;


function getValFromElem($elem, fallback = '') {

	if (!($elem instanceof jQuery)) $elem = $($elem);
	const type = $elem.attr('type');

	let val = $elem.val();

	if (type === 'number') val = parseInt(val);
	if (typeof val === 'string') val = val.trim();
	if (type === 'checkbox') val = $elem.is(':checked') ? val : false;

	return val || fallback;
}


function getValFromElems($elems) {
	const array = [];
	$elems.each((index, elem) => {
		const val = getValFromElem(elem)
		if (val) array.push(val);
	});
	return array.filter((v, i, a) => a.indexOf(v) === i); 
}


function setValToElems($elems, value) {

	const valueIsArray = Array.isArray(value)
	const valueIsString = (typeof value === 'string')

	$elems.each((index, elem) => {

		const val = getValFromElem(elem)

		if (elem.getAttribute('type') === 'checkbox') {
			// if it's a string, just set the value
			(valueIsString && valueIsString === val) && (elem.checked = true);
			(valueIsString && valueIsString !== val) && (elem.checked = false);
			// if the value is an array check if it's in the array
			(valueIsArray && value.indexOf(val) !== -1) && (elem.checked = true);
			(valueIsArray && value.indexOf(val) === -1) && (elem.checked = false);
			
		} else {
			
			valueIsString && (elem.value = value); 
			(valueIsArray && value.length) && (elem.value = value[0]); 
			(valueIsArray && !value.length) && (elem.value = ""); 
		}
	});
}


function SearchModel(options = {}) {

	this.changeHandlers = [];

	// discount URL parameters in searchUrl
	this.searchUrlBlacklist = [];
	if (options.searchUrlBlacklist && options.searchUrlBlacklist.length) {
		this.searchUrlBlacklist = options.searchUrlBlacklist;
	}

	const elems = options.fields || {};

	this.FILTERS = options.FILTERS || [];

	this.data = {
		search : '',
		location : [],
		jobFamily : [],
		jobType : [],
		orderBy : '',
		perPage : '',
		page : '',
		initialLoad : true,
		isLoading : false,
		response : false,
		apiUrl : '',
		searchUrl : '',
		apiBaseUrl : '',
		searchBaseUrl : '',
		onPopState : false,
	};

	// get elems
	this.$search = $(elems.search);
	this.$location = $(elems.location);
	this.$jobFamily = $(elems.jobFamily);
	this.$jobType = $(elems.jobType);
	this.$orderBy = $(elems.orderBy);
	this.$perPage = $(elems.perPage);
	this.$page = $(elems.page);

	// get initial values
	this.getFormFieldVals();
	
	this.data.searchBaseUrl = window.siteSettings && window.siteSettings.searchUrl || "";
	this.data.apiBaseUrl = window.siteSettings && window.siteSettings.apiUrl || "";

	// merge initialData with data
	options.initialData && (this.data = {
		...this.data,
		...options.initialData
	});

	this.set('apiUrl', this.buildAPIUrl(), false);
	this.set('searchUrl', this.buildSearchPageUrl(), false);

	// setup change handlers
	this.$search.length && this.$search.on('keyup', debounce((event) => {
		const value = getValFromElem(event.currentTarget);
		if (value === this.data.search) return;
		this.set('search', value, false);
		this.set('page', 1, false);
		this.set('orderBy', this.data.search ? 'relevance' : 'latest', false);
		
		// manually call getJobs if the value is different
		this.getJobs();
		// run change handlers becase we've not updated them with `this.set` above
		this.runChangeHandlers();
	}, 300));

	// single-select / input elems
	this.$orderBy.length && this.$orderBy.on('change', (event) => this.set('orderBy', getValFromElem(event.currentTarget)));
	this.$perPage.length && this.$perPage.on('change', (event) => this.set('perPage', getValFromElem(event.currentTarget)));
	this.$page.length && this.$page.on('change', (event) => this.set('page', getValFromElem(event.currentTarget)));	

	// elems can have multi-select / checkboxes

	// needs to be able to deal with multiple selects using the same model
	// multiple checkboxes
	// singleselects
	const updateVal = (event, type) => {
		const isCheckbox = (event.currentTarget.getAttribute('type') === 'checkbox');
		const changedVal = getValFromElem(event.currentTarget);
		const allVals = getValFromElems(this[`$${type}`]);
		this.set('page', 1, false); // set page back to 1 if the search changes
		isCheckbox && (this.set(type, allVals));
		!isCheckbox && changedVal && (this.set(type, [changedVal]));
		!isCheckbox && !changedVal && (this.set(type, []));
	}

	this.$jobFamily.length && this.$jobFamily.on('change', (event) => updateVal(event, 'jobFamily'));
	this.$location.length && this.$location.on('change', (event) => updateVal(event, 'location'));
	this.$jobType.length && this.$jobType.on('change', (event) => updateVal(event, 'jobType'));
}


SearchModel.prototype.getFormFieldVals = function() {

	// single-select / input elems

	this.$search.length && this.set('search', getValFromElem(this.$search), false);
	const orderBy = getValFromElem(this.$orderBy, 'latest');
	this.set('orderBy', this.data.search ? 'relevance' : this.data.orderBy, false);
	this.$perPage.length && this.set('perPage', getValFromElem(this.$perPage, 20), false)
	this.$page.length && this.set('page', getValFromElem(this.$page, 1), false);

	// multi-select / checkboxes

	this.$location.length && this.set('location', getValFromElems(this.$location), false);
	this.$jobFamily.length && this.set('jobFamily', getValFromElems(this.$jobFamily), false);
	this.$jobType.length && this.set('jobType', getValFromElems(this.$jobType), false);
	
	return this.data;
}


SearchModel.prototype.set = function(key, value, runChangeHandler = true) {

	if (key === 'perPage') value = parseInt(value);
	if (key === 'page') value = parseInt(value);

	// set data on input
	if (key === 'search') this.$search.val(value);
	if (key === 'orderBy') this.$orderBy.val(value);
	if (key === 'perPage') this.$perPage.val(value);
	if (key === 'page') this.$page.val(value);

	if (key === 'location') setValToElems(this.$location, value);
	if (key === 'jobFamily') setValToElems(this.$jobFamily, value);
	if (key === 'jobType') setValToElems(this.$jobType, value);
	
	// set on data prop
	this.data[key] = value;

	// if any URL props change, update URLs and get jobs
	const updateUrlProps = ['search', 'location', 'jobFamily', 'jobType', 'orderBy', 'perPage', 'page'];
	const isUpdatingUrl = updateUrlProps.indexOf(key) !== -1;

	if (isUpdatingUrl) {
		this.set('apiUrl', this.buildAPIUrl(), false);
		this.set('searchUrl', this.buildSearchPageUrl(), false);
	}

	// only update jobs when we're intending to run the changeHandler
	// stops running lots of requests when non-change updates 
	// are made (e.g. changing the orderBy prop when search changes)
	if (runChangeHandler && isUpdatingUrl) {
		this.set('isLoading', true, false);
		this.getJobs();
	}

	if (runChangeHandler) {
		this.runChangeHandlers();
	}
	return true;
}


SearchModel.prototype.get = function(key) {
	return (typeof this.data[key] === 'undefined') ? null : this.data[key];
}


SearchModel.prototype.replaceState = function(newData = {}) {
	for (let property in newData) {
		this.set(property, newData[property], false);
	}
	this.runChangeHandlers();
}


SearchModel.prototype.onChange = function(func = function() {}) {
	if (typeof func === "function") {
		this.changeHandlers.push(func);
	}
}


SearchModel.prototype.runChangeHandlers = function() {
	this.changeHandlers.forEach((func) => {
		func(this.data);
	});
}


SearchModel.prototype.buildAPIUrl = function() {

	if (!window.siteSettings || !window.siteSettings.apiUrl) return false;

	const url = new URI(window.siteSettings.apiUrl + '/jobs');

	for (let parameter of [
		'search',	'location', 'jobFamily', 'jobType',
		'orderBy', 'perPage', 'page'
	]) {
		let val = this.data[parameter];
		if (!val) continue;
		if (Object(val) !== val) url.setQuery(parameter, val);
		if (Array.isArray(val) && val.length) url.setQuery(parameter, val.join(','));
	}
	return url.toString();
}


SearchModel.prototype.buildSearchPageUrl = function() {

	if (!window.siteSettings || !window.siteSettings.searchUrl) return false;

	const url = new URI(window.siteSettings.searchUrl);

	for (let parameter of [
		'search',	'location', 'jobFamily', 'jobType',
		'orderBy', 'perPage', 'page'
	]) {
		if (this.searchUrlBlacklist.indexOf(parameter) !== -1) continue;
		let val = this.data[parameter];
		if (!val) continue;
		if (Object(val) !== val) url.setQuery(parameter, val);
		if (Array.isArray(val) && val.length) url.setQuery(parameter, val.join(','));
	}
	return url.toString();
}


SearchModel.prototype.getJobs = function() {
	const APIUrl = this.data.apiUrl;
	// get jobs
	$.ajax({
        url: APIUrl,
        error : (jqXHR, textStatus, errorThrown) => {
        	this.set('isLoading', false, false);
        	this.set('response', false, false);
        	this.set('initialLoad', false, false);
        	this.runChangeHandlers();
        },
        success: (response, status) => {
        	this.set('isLoading', false, false);
        	this.set('response', response, false); 	
        	this.set('initialLoad', false, false);
        	this.runChangeHandlers();
        }
    });
}


SearchModel.prototype.getFilters = function(selectedOnly = false) {

	const removeSelectedKey = (item) => {
		if (item.Selected) delete item.Selected;
		return item;
	}

	const isSelected = (key, selectedOnly) => {
		if (!selectedOnly) return () => true;
		return (item) => {
			return (this.data[key].indexOf(item.Slug) !== -1)
		}
	}

	const filters = {

		jobFamily : (this.FILTERS.jobFamily ? this.FILTERS.jobFamily : [])
			.map(removeSelectedKey)
			.filter(isSelected('jobFamily', selectedOnly)),

		location : (this.FILTERS.location ? this.FILTERS.location : [])
			.map(removeSelectedKey)
			.filter(isSelected('location', selectedOnly)),

		jobType : (this.FILTERS.jobType ? this.FILTERS.jobType : [])
			.map(removeSelectedKey)
			.filter(isSelected('jobType', selectedOnly)),
	}

	return filters;	
}


SearchModel.prototype.getFiltersFlat = function(selectedOnly = false) {

	const filters = this.getFilters(selectedOnly);
	const filtersFlat = [];

	for (let filterType in filters) {
		let filterGroup = filters[filterType];

		// non-array types, e.g. search - just set name / type
		if(!Array.isArray(filterGroup)) {
			if (filterGroup) {
				filtersFlat.push({
					'Name' : filterGroup,
					'type' : filterType,
				});
				continue;
			}
		}

		// array times, get full item
		filterGroup.map((filter) => {
			filter.type = filterType
			filtersFlat.push(filter);
		});
	}

	return filtersFlat;
}


SearchModel.prototype.getSelectedJobFamilies = function() {
	return this.getFilters(true).jobFamily || {};
}


SearchModel.prototype.getSelectedLocations = function() {
	return this.getFilters(true).location || {};
}


export default {
	SearchModel
}




